const mutations = {
    addRewards(state, rewardsArray) {
        state.rewards = rewardsArray;
    },
    lessAmount(state, id) {
        state.rewards[id].attributes.amount -= 1;
    },
    addWinReward(state, reward) {
        state.reward = reward;
    },
    changeWin(state, bool) {
        state.win = bool;
    },
    addLeadId(state, id) {
        state.leadId = id;
    },
    registerLeadOffline(state, lead) {
        var leadsLocalStorage = localStorage.getItem("leadsOffline");
        if(leadsLocalStorage) {
            leadsLocalStorage = JSON.parse(leadsLocalStorage);
            leadsLocalStorage.push(lead);
            return;
        }
        var leads = [];
        leads.push(lead);
        localStorage.setItem("leadsOffline", leads);
    }
}

export default mutations;