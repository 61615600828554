import mutations from "./mutations";
import actions from "./actions";

export default {
    state: {
        leadId: -1,
        rewards: [
            {
                enabled: true,
                name: "Big Rocks",
                img: "bobs.png",
                text: "",
                color: "#95211A",
                chance: 1,
                amount: 5
            },
            {
                enabled: true,
                name: "Milk Shake Crocante",
                img: "",
                text: "Shake Crocante",
                color: "#DC4A34",
                chance: 1,
                amount: 5
            },
            {
                enabled: true,
                name: "Milk Shake de Morango",
                img: "",
                text: "Milk Morango",
                color: "#95211A",
                chance: 9.83,
                amount: 125
            },
            {
                enabled: true,
                name: "Canudos Retráveis",
                img: "",
                text: "Retráveis",
                color: "#DC4A34",
                chance: 39.37,
                amount: 500
            },
            {
                enabled: true,
                name: "Big Rocks",
                img: "",
                text: "Big Rocks",
                color: "#95211A",
                chance: 1,
                amount: 5
            },
            {
                enabled: true,
                name: "Milk Shake Crocante",
                img: "",
                text: "Crocante",
                color: "#DC4A34",
                chance: 1,
                amount: 5
            },
            {
                enabled: true,
                name: "Milk Shake de Morango",
                img: "",
                text: "Morango",
                color: "#95211A",
                chance: 9.83,
                amount: 125
            },
            {
                enabled: true,
                name: "Canudos Retráveis",
                img: "",
                text: "Canudos",
                color: "#DC4A34",
                chance: 39.37,
                amount: 500
            }
        ],
        win: true,
        reward: {
            enabled: true,
            name: "Big Rocks",
            img: "bobs.png",
            text: "",
            color: "#95211A",
            chance: 10,
            amount: 5
        }
    },
    mutations,
    actions
}