export default [
    {
        path: "/",
        name: "Admin",
        component: () => import("./pages/admin/Admin.vue")
    },
    {
        path: "/MainGame/:id",
        name: "mainGame",
        component: () => import("./pages/mainGame/MainGame.vue")
    }
]