import mutations from "./mutations";

export default {
    state: {
        quests: [],
        configs: {
            minRightAnswers: 0,
            maxQuests: 0
        }
    },
    mutations
}