import mutations from "./mutations";

export default {
    state: {
        logged: {
            enabled: false
        },
        isConnected: true,
        pageCurrent: 0,
        positionPage: 0,
        rowPages: [0,1,4,2,3],
        offlineGame: -1
    },
    mutations
}