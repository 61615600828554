export default {
    changePageCurrent(state, page) {
        state.pageCurrent = page;
    },
    changePagePosition(state, number) {
        state.positionPage += number;
        state.pageCurrent = state.rowPages[state.positionPage];
    },
    returnHome(state) {
        state.positionPage = 0;
        state.pageCurrent = state.rowPages[0];
    },
    lastPage(state) {
        state.positionPage = state.rowPages.length - 1;
        state.pageCurrent = state.rowPages[state.positionPage];
    },
    addRoutesPages(state, array) {
        state.rowPages = array;
    },
    changeIsConnected(state, bool) {
        state.isConnected = bool;
    },
    savedGameOffline(state, gameInfos) {
        localStorage.setItem("gameOffline", gameInfos);
    },
    changeOfflineGame(state, number) {
        state.offlineGame = number;
    }
}