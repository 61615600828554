import Vuex from 'vuex'

import rewardsModule from './modules/rewards/rewards'
import configsModule from './modules/configs/configs'
import routerPagesModule from './modules/routerPages/routerPages'
import quizModule from './modules/quiz/quiz'

export default new Vuex.Store({
    modules: {
        rewardsModule,
        configsModule,
        routerPagesModule,
        quizModule
    }
})