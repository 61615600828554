const mutations = {
    changeBackground(state, background) {
        state.background = background;
    },
    changeImgButton(state, imgButton) {
        state.imgButton = imgButton;
    },
    changeLogo(state, logo) {
        state.homeLogo = logo;
    },
    addColorsConfig(state, colors) {
        state.colors = JSON.parse(colors);
    },
    addTextsConfig(state, texts) {
        state.texts = JSON.parse(texts);
        console.log(state.texts);
    },
    addAllConfigs(state, config) {
        state = config;
    }
}

export default mutations;