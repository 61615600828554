import mutations from "./mutations";
import actions from "./actions";

export default {
    state: {
        background: "background.jpg",
        imgButton: "bobs.png",
        homeLogo: "bobs.png",
        colors: {},
        texts: {}
    },
    mutations,
    actions
}